/* global $ */
import '../../css/app/components/home.scss';
class Home
{
    speed;
    has_fade_effect;

    constructor () 
    {
        let speed = $(".slideshow.banner-slider").data('speed');
        if (speed) {
            speed = speed * 1000; //Get milliseconds
        } else {
            speed = 5000; //Default speed transition
        }

        this.speed = speed;
        this.has_fade_effect = $(".slideshow.banner-slider").data('has-fade-effect');
        this.initBannerSlider();
        
        console.log('Home page initialised');
    }

    initBannerSlider() {
        import(/* webpackChunkName: "slick" */ '../components/slick').then(() => {
            $('.banner-slider').slick({
                rows: 0,
                autoplay: true,
                autoplaySpeed: this.speed,
                infinite: true,
                speed: 1000,
                fade: !!(this.has_fade_effect),
                cssEase: 'linear'
            });
        });
    }
}

new Home();